(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/content/logotype-provider/assets/javascripts/provider.js') >= 0) return;  svs.modules.push('/components/content/logotype-provider/assets/javascripts/provider.js');

'use strict';

const isServer = typeof exports === 'object';
const nameSafeString = function () {
  let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const safeString = string.replace(/^[.\s]+/g, '').replace(/[?!"()&€%]/g, '').replace(/[@]/g, '_a_').replace(/[åäáà]/gi, 'a').replace(/[é]/gi, 'e').replace(/[ö]/gi, 'o').replace(/[^a-z0-9._-]/gi, '-').toLowerCase();
  return safeString;
};
const getImages = async (req, configName, configParentId) => {
  const path = "/proxy/cms/tur/rest/v2/sitesetting/name/".concat(configName, "?parentId=").concat(configParentId);
  if (isServer) {
    const trinidad = require('trinidad-core').core;
    const options = {
      method: 'GET',
      path,
      req
    };
    try {
      return await trinidad.jupiter.callAsync(options);
    } catch (err) {
      return null;
    }
  }

  const {
    jupiter
  } = svs.core;
  const response = await jupiter.get(path);
  if (response) {
    return response;
  }
};
const getLogotypes = async (req, configName, configParentId) => {
  const data = await getImages(req, configName, configParentId);
  const {
    siteSetting: siteSettingString,
    imageRelations
  } = data;
  const siteSetting = siteSettingString && JSON.parse(siteSettingString) || {};
  const {
    logotypes
  } = siteSetting;
  const output = logotypes.map(logotype => {
    logotype.id = nameSafeString(logotype.name);
    return logotype;
  });
  return {
    imageRelations,
    logotypes: output
  };
};
if (isServer) {
  module.exports = {
    getLogotypes
  };
} else {
  svs.content.logotypeProvider = {
    getLogotypes
  };
}

 })(window);